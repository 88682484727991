<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        The Iron Age is a term that describes when iron tools were being made; this is a result of
        humans figuring out how to smelt iron via the reaction:
      </p>

      <p class="pl-10 mb-4">
        <chemical-latex content="2 Fe2O3(s) + 3 C(s) <=> 4 Fe(s) + 3 CO2(g)" />
      </p>

      <p class="mb-3">
        Use the information in the table below to answer the questions that follow.
      </p>

      <v-simple-table class="mb-5" style="max-width: 500px" dense>
        <thead>
          <tr>
            <th style="vertical-align: middle; font-size: 15px"></th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="Fe2O3(s)" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="C(s)" />
            </th>
            <th>
              <chemical-latex content="<=>" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="Fe(s)" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="CO2(g)" />
            </th>
          </tr>
        </thead>
        <!-- Body of the table -->
        <tbody>
          <tr>
            <td style="vertical-align: middle; font-size: 15px">
              <stemble-latex content="$\Delta\text{H}_{\text{f}}^\circ\text{ (kJ/mol)}$" />
            </td>
            <td style="vertical-align: middle; text-align: center; font-size: 13px">
              <stemble-latex content="$-824.2$" />
            </td>
            <td style="vertical-align: middle; text-align: center; font-size: 13px">
              <stemble-latex content="$0$" />
            </td>
            <td style="vertical-align: middle; text-align: center; font-size: 13px"></td>
            <td style="vertical-align: middle; text-align: center; font-size: 13px">
              <stemble-latex content="$0$" />
            </td>
            <td style="vertical-align: middle; text-align: center; font-size: 13px">
              <stemble-latex content="$-393.5$" />
            </td>
          </tr>

          <tr>
            <td style="vertical-align: middle; font-size: 15px">
              <stemble-latex content="$\text{S}^\circ\text{ (J/K}\cdot\text{mol)}$" />
            </td>
            <td style="vertical-align: middle; text-align: center; font-size: 13px">
              <stemble-latex content="$87.4$" />
            </td>
            <td style="vertical-align: middle; text-align: center; font-size: 13px">
              <stemble-latex content="$5.7$" />
            </td>
            <td style="vertical-align: middle; text-align: center; font-size: 13px"></td>
            <td style="vertical-align: middle; text-align: center; font-size: 13px">
              <stemble-latex content="$27.3$" />
            </td>
            <td style="vertical-align: middle; text-align: center; font-size: 13px">
              <stemble-latex content="$213.8$" />
            </td>
          </tr>

          <tr>
            <td style="vertical-align: middle; font-size: 15px">
              <stemble-latex content="$\Delta\text{G}_{\text{f}}^\circ\text{ (kJ/mol)}$" />
            </td>
            <td style="vertical-align: middle; text-align: center; font-size: 13px">
              <stemble-latex content="$-742.3$" />
            </td>
            <td style="vertical-align: middle; text-align: center; font-size: 13px">
              <stemble-latex content="$0$" />
            </td>
            <td style="vertical-align: middle; text-align: center; font-size: 13px"></td>
            <td style="vertical-align: middle; text-align: center; font-size: 13px">
              <stemble-latex content="$0$" />
            </td>
            <td style="vertical-align: middle; text-align: center; font-size: 13px">
              <stemble-latex content="$-394.4$" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2">
        a) What is the value of
        <stemble-latex content="$\Delta\text{H}^\circ_{\text{rxn}}$" />
        for the reaction in
        <stemble-latex content="$\text{kJ/mol?}$" />
      </p>

      <calculation-input
        v-model="inputs.deltaH"
        class="mb-6"
        prepend-text="$\Delta\text{H}^\circ_{\text{rxn}}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        a) Determine
        <stemble-latex content="$\Delta\text{S}^\circ_{\text{rxn}}$" /> for the reaction in
        <stemble-latex content="$\text{J/K}\cdot\text{mol?}$" />
      </p>

      <calculation-input
        v-model="inputs.deltaS"
        class="mb-6"
        prepend-text="$\Delta\text{S}^\circ_{\text{rxn}}:$"
        append-text="$\text{(J/K}\cdot\text{mol)}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) Determine the standard Gibb's Free energy change,
        <stemble-latex content="$\Delta\text{S}^\circ_{\text{rxn}},$" />
        of the reaction in
        <stemble-latex content="$\text{kJ/mol}.$" />
      </p>

      <calculation-input
        v-model="inputs.deltaG"
        class="mb-6"
        prepend-text="$\Delta\text{G}^\circ_{\text{rxn}}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        d) What is the equilibrium constant for this reaction at
        <stemble-latex content="$298\,\text{K?}$" />
      </p>

      <calculation-input
        v-model="inputs.Keq"
        class="mb-6"
        prepend-text="$\text{K}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        e) At what temperature (in K) does the value of the equilibrium constant begin to favor
        products? <b>Note</b>: This is also called the temperature at which the reaction becomes
        feasible.
      </p>

      <calculation-input
        v-model="inputs.temp"
        class="mb-6"
        prepend-text="$\text{T}:$"
        append-text="$\text{K}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        f) Why is the value of
        <stemble-latex content="$\Delta\text{G}^\circ_{\text{rxn}}$" />
        <stemble-latex
          content="$\neq \Delta\text{H}^\circ_{\text{rxn}}-\text{T}\,\Delta\text{S}^\circ_{\text{rxn}}?$"
        />
      </p>

      <p class="mb-n6">
        <s-textarea v-model="inputs.explanation" outlined rows="3" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';
import NumberValue from '../NumberValue';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'cpp1220Exam3Q2',
  components: {
    STextarea,
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        deltaH: null,
        deltaS: null,
        deltaG: null,
        Keq: null,
        temp: null,
        explanation: null,
      },
    };
  },
};
</script>
